import React from "react";
import Styles from "./RelevancyTags.module.scss";
import Typography from "@vahak/core-ui/dist/components/Typography";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import MarqueeText from "../../load-lorry-details/components/marquee-text/MarqueeText";
import TextMarquee from "../../text-maquee/TextMarquee";

interface RelevancyTagsProps {
    id?: string;
    tags: string[] | null;
}

const RelevancyTags = ({ id, tags }: RelevancyTagsProps) => {
    if (!tags) return <></>;
    return (
        <div className={Styles.main} id={`${id}--relevancyTags`}>
            <TextMarquee text={tags.join(", ")} />
        </div>
    );
};

export default RelevancyTags;
