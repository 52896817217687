import React from "react";
import Chip from "@vahak/core-ui/dist/components/Chip";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";

import TimerIcon from "@vahak/core/dist/standard-icons/timer.svg";

import COLORS from "@vahak/core-ui/dist/constants/colors";

import { addDaysOnDate, formatDate } from "@vahak/core-ui/dist/methods/time";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Typography from "@vahak/core-ui/dist/components/Typography";

interface LoadingTimeBadgeProps {
    renderText?: string;
    time?: Date;
    id?: string;
}

const generateFormattedString = (time: Date) => {
    const today = new Date();
    let day = "";
    if (time.toDateString() === today.toDateString()) {
        day = "Today";
    } else if (time.toDateString() === addDaysOnDate(today, 1).toDateString()) {
        day = "Tomorrow";
    } else {
        day = formatDate(time, "DD/MM/YY");
    }

    return `Loading ${day} at ${formatDate(time, "h:mm a ")}`;
};

const LoadingTimeBadge = ({ id, renderText, time }: LoadingTimeBadgeProps) => {
    const str = time ? generateFormattedString(time) : "Loading time not added.";

    return (
        <Flex alignItems="center" gap={4} id={`${id}--loadingTimestamp`}>
            <IconWrapper>
                <TimerIcon />
            </IconWrapper>
            <Typography size="xs" color={COLORS.ORANGE}>
                {renderText ?? str}
            </Typography>
        </Flex>
    );
};

export default LoadingTimeBadge;
