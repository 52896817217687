import React from "react";
import Styles from "./TextMarquee.module.scss";
import Typography, { TypographyProps } from "@vahak/core-ui/dist/components/Typography";
import COLORS from "@vahak/core-ui/dist/constants/colors";

interface TextMarqueeProps {
    text: string;
    fontSize?: TypographyProps["size"];
    weight?: TypographyProps["weight"];
    color?: TypographyProps["color"];
}

const TextMarquee = ({ text, fontSize, weight, color }: TextMarqueeProps) => {
    return (
        <div className={Styles.marquee}>
            <Typography size={fontSize ?? "xs"} weight={weight ?? "medium"} color={color ?? COLORS.GREY_700}>
                {text}
            </Typography>
        </div>
    );
};

export default TextMarquee;
